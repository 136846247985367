/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthService, DeviceTypes, MessageService, PinColorStatusType, Roles, Utilities } from '@SiteOwl/core';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { DragulaService } from "ng2-dragula";
import { Subject, Subscription, fromEvent, takeUntil } from 'rxjs';
import { DeleteFav } from '../../constant/delete-fav.contant';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CommonPopupComponent } from '@SiteOwl/UI';
// @ts-ignore: Unreachable code error
import autoScroll from 'dom-autoscroller';
import { FavImportExportComponent } from '../fav-import-export/fav-import-export.component';
import { PreviewImportComponent } from '../preview-import/preview-import.component';
import { FloorPlanService } from '../../service/floor-plan.service';
import { FloorUtils } from '../../service/floor-utils';


@Component({
  selector: 'so-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentListComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @Input() equipmentList: any;
  @Input() isProject: any;
  @Input() pinColorStatus: any;
  @Input() permissions!: any;
  @Input() pinSize: any;
  @Input() isEdit: any;
  @Input() zoomFactor: any;
  @Input() favoriteEquipmentData: any;
  @Input() fromProject: any;
  @Input() defaultFavoriteEquipmentData: any;
  @Output() deviceUpdate = new EventEmitter();
  @Output() favOrderChanged = new EventEmitter();
  @Output() changeValueFromFavorite = new EventEmitter();
  @Output() changeFav = new EventEmitter();
  @Output() inputFavCalled = new EventEmitter();

  @ViewChild('asideElement', { static: true }) asideElement: ElementRef | undefined;
  @ViewChild("autoscroll", { static: true }) autoscroll: ElementRef | undefined;
  searchText = new FormControl();
  defaultEquipmentList: any;
  isOpenFavorite = false;
  isOpenOther = true;
  selectedAccordinName!: string;
  selectedAccordin!: number;
  pinColorStatusType = PinColorStatusType
  selectedFavorite!: any;
  dragId: any;
  dragulaSub: Subscription = new Subscription();
  selectedItem: any;
  equipmentDetailData: any;
  currentEquipmentDetailsIndex: any;
  baseNameCount: any = [];
  baseAbbrivatedNameCount: any;
  bsModalRef?: BsModalRef;
  equipmentDetails: any;
  isFavAvailable: any;
  currentEquipmentDetailsParentIndex: any;
  contextmenuX: any;
  contextmenuY: any;
  selectedItemForRemove: any;
  contextmenu!: boolean;
  bsModalPreviewRef!: BsModalRef;
  bsModalPreviewCompletedRef!: BsModalRef;
  user!: any;
  isCustomerAgent!: any;
  favFile!: any
  private readonly destroy$ = new Subject<void>();
  showSpinner = false;

  constructor(private dragulaService: DragulaService, private messageService: MessageService,
    private floorPlanService: FloorPlanService,
    private cdRef: ChangeDetectorRef, private authService: AuthService,
    private modalService: BsModalService) { }
  ngOnInit() {
    const click$ = fromEvent(document, 'click');
    click$.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.closeContextMenu();
      if (this.equipmentDetails && !Utilities.isEmpty(res.target.id) && res.target.id !== 'parentContainer') {
        this.closeEquipmentDetail({ equipmentDetails: this.equipmentDetails, showEquipment: false });
      }
    });
    this.defaultEquipmentList = _.cloneDeep(this.equipmentList);
    if (!this.dragulaService.find("FAVORITES")) {
      this.dragulaService.createGroup("FAVORITES", {
        direction: "horizontal",
        moves: (el: any, container, handle: any) => {
          return handle.classList.contains('handle');
        }
      });
    }
    this.dragulaSub.add(this.dragulaService.drag("FAVORITES")
      .subscribe(({ name, el, source }) => {
        this.dragId = el.id;
        document.body.classList.add("fav-reorder-main")

      })
    );
    this.dragulaSub.add(this.dragulaService.dragend("FAVORITES")
      .subscribe(({ name, el }) => {

        document.body.classList.remove("fav-reorder-main")

      })
    );
    this.isFavAvailable = this.isFavoriteEquipmentListAvailable();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['favoriteEquipmentData'] && this.favoriteEquipmentData !== undefined) {
      this.favoriteEquipmentData = _.cloneDeep(changes['favoriteEquipmentData'].currentValue);
      this.isFavAvailable = this.isFavoriteEquipmentListAvailable();
    }
    if (changes['defaultFavoriteEquipmentData'] && this.defaultFavoriteEquipmentData !== undefined) {
      if (this.equipmentDetails) {
        if (this.favoriteEquipmentData.filter((x: any) => x.orderNumber === this.equipmentDetails.orderNumber).length > 0) {
          this.equipmentDetails = this.favoriteEquipmentData.filter((x: any) => x.orderNumber === this.equipmentDetails.orderNumber)[0];
          this.currentEquipmentDetailsParentIndex = null;
        } else {
          this.favoriteEquipmentData.forEach((element: any, index: any) => {
            element.childFavouriteEquipments.forEach((elementChildFav: any, childIndex: any) => {
              if (elementChildFav.orderNumber === this.equipmentDetails.orderNumber) {
                this.equipmentDetails = elementChildFav;
                this.currentEquipmentDetailsIndex = childIndex;
                this.currentEquipmentDetailsParentIndex = index;
              }
            });
          }); 
        }
      }
    }
    this.cdRef.detectChanges();
  }
  ngAfterViewInit(): void {
    this.scroll();
    this.user = this.authService.getUserKeyData();
    this.isCustomerAgent = this.user.customerRoleName === Roles.Agent;
    const equipmentDeviceList = document.getElementById('equipment-device-list');
    if (equipmentDeviceList) {
      equipmentDeviceList.style.userSelect = 'none';
    }
  }
  filterCategories(isReset: boolean) {
    if (isReset) {
      this.searchText.setValue('')
    }
    const searchText = this.searchText.value.trim();
    const searchKeys = searchText.split(' ');
    const tempArray: any = [];
    const tempData = _.cloneDeep(this.defaultEquipmentList)
    tempData.shift()
    if (!Utilities.isEmpty(searchText)) {
      if (searchKeys.length === 1 || tempData.some((x: any) => x.equipments.some((y: any) => y.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1))) {
        this.defaultEquipmentList.forEach((category: any) => {
          const tempcategory = _.cloneDeep(category);
          if (tempcategory.id === 8) {
            tempArray.push(tempcategory);
          } else {
            tempcategory.equipments = tempcategory.equipments.filter((x: any) => x.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
            if (tempcategory.equipments.length > 0) {
              tempArray.push(tempcategory);
            }
          }
        });
        if (tempArray.length > 0) {
          this.equipmentList = _.cloneDeep(tempArray);
        }
      } else {
        this.defaultEquipmentList.forEach((category: any) => {
          const tempcategory = _.cloneDeep(category);
          if (tempcategory.id === 8) {
            tempArray.push(tempcategory);
          } else {
            searchKeys.forEach((searchKey: any) => {
              if (!Utilities.isEmpty(searchKey)) {
                tempcategory.equipments = tempcategory.equipments.filter((x: any) => x.name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
              }
            });
            if (tempcategory.equipments.length > 0) {
              tempArray.push(tempcategory);
            }
          }
        });
        this.equipmentList = _.cloneDeep(tempArray);
      }
    } else {
      this.equipmentList = _.cloneDeep(this.defaultEquipmentList);
    }
    if (!this.isOpenFavorite) {
      const accordianArray = _.cloneDeep(this.equipmentList);
      const indexOfAccordin = accordianArray.map((e: any) => e.name).indexOf(this.selectedAccordinName);
      if (indexOfAccordin < 0) {
        this.selectedAccordin = 0;
      } else {
        this.selectedAccordin = indexOfAccordin - 1;
      }
    }
  }

  isOpenChange(event: any, index: any) {
    if (event === true && index === -1) {
      this.isOpenFavorite = true;
      this.isOpenOther = false;
    } else if (event === true && index > -1) {
      this.isOpenFavorite = false;
      this.isOpenOther = true;
      this.selectedAccordin = index;
      this.selectedAccordinName = this.equipmentList[index].name;
    }
  }

  favImportExport(event: any) {
    event.stopPropagation();
    const modalData = {
      favoriteDownloadData: _.orderBy(this.defaultFavoriteEquipmentData, ['orderNumber'], ['asc']),
      fromProject: this.fromProject,
      favoriteEquipmentData: this.favoriteEquipmentData
    };
    const initialState: ModalOptions = {
      initialState: modalData, class: 'modal-xxxl', ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(FavImportExportComponent, initialState)
    this.bsModalRef.content.previewTrigger.subscribe((res: any) => {
      if (res) {
        this.bsModalRef?.setClass('modal-xxxl d-none')
        const initialStateForPreview: ModalOptions = {
          initialState: {
            previewData: res.previewResponse,
            isCompleted: false
          }, class: 'modal-xxl', ignoreBackdropClick: true
        };
        this.favFile = res.file;
        this.bsModalPreviewRef = this.modalService.show(PreviewImportComponent, initialStateForPreview)
        this.bsModalPreviewRef.content.importCSV.subscribe((previewRes: any) => {
          if (previewRes) {
            this.showSpinner = true
            this.floorPlanService.uploadCsvFavorite(this.favFile, false, this.fromProject).subscribe((previewResponse: any) => {
              this.showSpinner = false;
              this.bsModalPreviewRef?.setClass('modal-xxxl d-none')
              const initialStateForPreview: ModalOptions = {
                initialState: {
                  previewData: res.previewResponse,
                  isCompleted: true
                }, class: 'modal-xxl', ignoreBackdropClick: true
              };
              this.bsModalPreviewCompletedRef = this.modalService.show(PreviewImportComponent, initialStateForPreview)
              this.bsModalPreviewCompletedRef.content.importCSV.subscribe((res: any) => {
                this.changeFav.emit();
                this.bsModalPreviewCompletedRef.hide();
                this.bsModalPreviewRef.hide();
                this.bsModalRef?.hide();
              });
            }, (error: any) => {
              if (error.status === 422) {
                this.messageService.errorMessage('Upload CSV', error.error.message);
              } else {
                this.messageService.errorMessage('Upload CSV', error.message);
              }
              this.showSpinner = false;
            });
          } else {
            this.bsModalRef?.setClass('modal-xxxl modal-80')
            this.bsModalPreviewRef.hide();
          }
        })
      }
    });
  }
  updateDeviceLeftTop(event: any) {
    this.deviceUpdate.emit(event);
  }
  scroll() {
    const timeOut = setTimeout(() => {
      autoScroll([
        this.autoscroll?.nativeElement,
      ], {
        margin: 20,
        autoScroll: function () {
          return this.down
        }
      });
      clearTimeout(timeOut);
    }, 1000);

  }
  showEquipmentModalForFavorites(equipment: any, isChild?: any) {
    const dataReplacer = {
      '{{#equipmentName}}': _.escape(equipment.name),
    }
    const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(DeleteFav));
    const initialState: ModalOptions = {
      initialState: modalData, class: DeleteFav.dialogClass
    };
    this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res !== 'Cancel') {
        equipment.isDeleted = true;
        if (isChild) {
          if (equipment.id < 1) {
            this.favoriteEquipmentData[this.currentEquipmentDetailsIndex].childFavouriteEquipments.splice(this.favoriteEquipmentData[this.currentEquipmentDetailsIndex].childFavouriteEquipments.findIndex((e: any) => e.id === equipment.id), 1);
          } else {
            this.favoriteEquipmentData[this.currentEquipmentDetailsIndex].childFavouriteEquipments.filter((e: any) => e.id === equipment.id)[0].isDeleted = true;
          }
          if (this.equipmentDetails) {
            this.equipmentDetails = _.cloneDeep(this.favoriteEquipmentData[this.currentEquipmentDetailsIndex]);
          }
        } else {
          if (equipment.id < 1) {
            this.favoriteEquipmentData.splice(this.favoriteEquipmentData.findIndex((e: any) => e.id === equipment.id), 1)
          } else {
            this.favoriteEquipmentData.filter((e: any) => e.id === equipment.id)[0].isDeleted = true;
          }
        }
        this.bsModalRef?.hide();
        this.bsModalRef = undefined;
        this.cdRef.detectChanges();
        this.changeValueFromFavorite.emit({ favoriteEquipmentData: this.favoriteEquipmentData, equipment: equipment, isBackClickedWithNoDataChange: true, equipmentDetails: this.equipmentDetailData });
      }
    })
  }
  openEquipmentDetail(item: any, index: any) {
    this.equipmentDetails = item;
    this.currentEquipmentDetailsParentIndex = null;
    this.currentEquipmentDetailsIndex = index;
    this.cdRef.detectChanges();
  }
  onDragulaModelChanges(data: any) {
    let orderNumber = 0;

    data.forEach((element: any, index: any) => {
      orderNumber += 1;
      element.orderNumber = orderNumber;
      element.index = index;
      if (element.childFavouriteEquipments && element.childFavouriteEquipments.length > 0) {
        element.childFavouriteEquipments = _.orderBy(element.childFavouriteEquipments, 'orderNumber')
        element.childFavouriteEquipments.forEach((element1: any) => {
          orderNumber += 1;
          element1.orderNumber = orderNumber;
        });
      }
    });
    this.favoriteEquipmentData = data;
    if (this.dragId) {
      const elementIndex = data.map((e: any) => e.id).indexOf(parseInt(this.dragId));
      if (data[elementIndex]) {
        data[elementIndex].isFavorateUpdate = true;
      }
      this.favOrderChanged.emit({ favoriteEquipmentData: data, equipment: data[elementIndex] });
    }
    if (!this.dragId) {
      this.favOrderChanged.emit({ favoriteEquipmentData: data });
    }
  }
  isFavoriteEquipmentListAvailable() {
    if (this.isProject) {
      return this.favoriteEquipmentData.some((x: any) => !x.isDeleted);
    } else {
      return this.favoriteEquipmentData.some((x: any) => !x.isDeleted && x.type !== DeviceTypes.Task);
    }
  }
  ngOnDestroy(): void {
    this.dragulaSub.unsubscribe();
    this.dragulaService.destroy('FAVORITES');
    this.destroy$.next();
    this.destroy$.complete();
  }
  optionSelected(selection: any, type: any) {
    if (type === 'list') {
      this.selectedItem = selection
    }
    if (selection === 'favorite' || type === 'list') {
      if (this.asideElement) {
        this.asideElement.nativeElement.scrollTop = 0;
      }
      this.isOpenOther = false
      this.isOpenFavorite = true
      const timeOut = setTimeout(() => {
        this.onEquipmentDrop(this.selectedItem)
        clearTimeout(timeOut)
      }, 0);
    }
  }
  onEquipmentDrop(data: any) {
    if (data.onSelectFrom !== 'floor' && data.onSelectFrom !== 'favorite' && Utilities.isNull(data.id)) {
      this.currentEquipmentDetailsIndex = null;
      this.equipmentDetailData = data;
      this.equipmentDetailData.id = null;
      let deviceOrTaskType = DeviceTypes.Device
      if (data.equipment.type === 'task') {
        deviceOrTaskType = DeviceTypes.Task
      }
      this.equipmentDetailData.type = deviceOrTaskType;
      this.equipmentDetailData.isDeleted = false;
      this.equipmentDetailData.equipment = data.equipment;
      this.equipmentDetailData.name = this.changeFavoriteEquipmentName(data.equipment.name);
      this.equipmentDetailData.abbreviatedName = (data.equipment.abbreviatedNamePrefix == null || data.equipment.abbreviatedNamePrefix == undefined) ? '' : data.equipment.abbreviatedNamePrefix;
      this.equipmentDetailData.typeName = data.equipment.name;
      this.equipmentDetailData.categoryName = this.equipmentList.
        find((parent: any) => {
          return parent.equipments.
            some((x: any) => x.id === data.equipmentId)
        }).name;
      this.equipmentDetailData.partNumber = null;
      this.equipmentDetailData.dataType = null;
      this.equipmentDetailData.index = this.favoriteEquipmentData.length;
      this.equipmentDetailData.isParent = true;
      const orderedFavEquipList = _.orderBy(this.favoriteEquipmentData, ['orderNumber']);
      this.equipmentDetailData.orderNumber = this.favoriteEquipmentData.length > 0 ? orderedFavEquipList[this.favoriteEquipmentData.length - 1].orderNumber + 1 : 1
      this.equipmentDetailData.pinColor = data.equipment.pinColor
      if (data.id === null) {
        this.equipmentDetailData.isNew = true;
      }
      this.equipmentDetailData.id = Math.random();
      this.equipmentDetailData.isFavorateUpdate = true;
      this.equipmentDetailData.isHub = data.equipment.isHub;
      this.favoriteEquipmentData.push(this.equipmentDetailData);
      this.selectedFavorite = this.favoriteEquipmentData.length - 1;
      this.cdRef.detectChanges();
      this.changeValueFromFavorite.emit({ favoriteEquipmentData: this.favoriteEquipmentData, equipment: this.equipmentDetailData, isBackClickedWithNoDataChange: true, equipmentDetails: this.equipmentDetailData });
      this.getDeviceNameAndBaseName(false);
    }
  }
  changeFavoriteEquipmentName(name: any, isFromFavorite?: boolean) {
    let cnt = 0;
    const deviceName = this.getBaseName(name).baseName;
    const existingDeviceCnt = this.baseNameCount.filter((x: any) => x.name === deviceName)[0];
    if (existingDeviceCnt) {
      existingDeviceCnt.count = existingDeviceCnt.count + 1
      cnt = existingDeviceCnt.count;
      return deviceName + " " + cnt;
    } else {
      this.baseNameCount.push({
        name: deviceName,
        count: 0
      })
      return deviceName;
    }
  }
  changeFavoriteEquipmentAbbrivatedName(name: any, isFromFavorite?: boolean) {
    let cnt = 0;
    const deviceName = this.getBaseAbbrivatedName(name).baseName;
    const existingDeviceCnt = this.baseAbbrivatedNameCount.filter((x: any) => x.name === deviceName)[0];
    if (existingDeviceCnt) {
      existingDeviceCnt.count = existingDeviceCnt.count + 1
      cnt = existingDeviceCnt.count;
      return deviceName + "-" + cnt;
    } else {
      this.baseAbbrivatedNameCount.push({
        name: deviceName,
        count: 0
      })
      return deviceName;
    }
  }
  async getDeviceNameAndBaseName(isFromFirst: any) {
    return new Promise<void>((resolve, reject) => {
      this.baseNameCount = [];
      if (this.favoriteEquipmentData && this.favoriteEquipmentData.length > 0) {
        this.favoriteEquipmentData.forEach((parent: any) => {
          if (!parent.isDeleted) {
            if (this.baseNameCount && this.baseNameCount.length > 0) {
              const deviceName = this.getBaseName(parent.name).baseName;
              const deviceCount = this.getBaseName(parent.name).count;
              const exsitingDevice = this.baseNameCount.filter((x: any) => x.name === deviceName)[0];
              if (exsitingDevice) {
                if (deviceCount > exsitingDevice.count) {
                  exsitingDevice.count = deviceCount
                }
              } else {
                this.baseNameCount.push({
                  name: this.getBaseName(parent.name).baseName,
                  count: 0
                })
              }
            } else {
              this.baseNameCount.push({
                name: this.getBaseName(parent.name).baseName,
                count: this.getBaseName(parent.name).count
              })
            }
          }

        })
        resolve();
      }
    });
  }
  async getDeviceNameAndBaseAbbrivatedName(isFromFirst: any) {
    return new Promise<void>((resolve, reject) => {
      this.baseAbbrivatedNameCount = [];
      if (this.favoriteEquipmentData && this.favoriteEquipmentData.length > 0) {
        this.favoriteEquipmentData.forEach((parent: any) => {
          if (!parent.isDeleted) {
            if (this.baseAbbrivatedNameCount && this.baseAbbrivatedNameCount.length > 0) {
              const deviceName = this.getBaseAbbrivatedName(parent.abbrivatedName).baseName;
              const deviceCount = this.getBaseAbbrivatedName(parent.abbrivatedName).count;
              const exsitingDevice = this.baseAbbrivatedNameCount.filter((x: any) => x.name === deviceName)[0];
              if (exsitingDevice) {
                if (deviceCount > exsitingDevice.count) {
                  exsitingDevice.count = deviceCount
                }
              } else {
                this.baseAbbrivatedNameCount.push({
                  name: this.getBaseAbbrivatedName(parent.abbrivatedName).baseName,
                  count: 0
                })
              }
              if (parent.childFavouriteEquipments && parent.childFavouriteEquipments.length > 0) {
                parent.childFavouriteEquipments.forEach((child: any) => {
                  const deviceChildName = this.getBaseAbbrivatedName(child.abbrivatedName).baseName;
                  const deviceChildCount = this.getBaseAbbrivatedName(child.abbrivatedName).count;
                  const exsitingChildDevice = this.baseAbbrivatedNameCount.filter((x: any) => x.name === deviceChildName)[0];
                  if (exsitingChildDevice) {
                    if (deviceChildCount > exsitingChildDevice.count) {
                      exsitingChildDevice.count = deviceChildCount
                    }
                  } else {
                    this.baseAbbrivatedNameCount.push({
                      name: this.getBaseAbbrivatedName(child.abbrivatedName).baseName,
                      count: 0
                    })
                  }
                })
              }
            } else {
              this.baseAbbrivatedNameCount.push({
                name: this.getBaseAbbrivatedName(parent.abbrivatedName).baseName,
                count: this.getBaseAbbrivatedName(parent.abbrivatedName).count
              })
            }
          }

        })
        resolve();
      }
    });
  }
  getBaseName(name: any) {
    if (name) {
      const nameArr: any[] = name.split(' ');
      if (nameArr.length > 1 && !isNaN(nameArr[nameArr.length - 1])) {
        let baseName: any;
        let count = 0;
        if (this.isNumeric(nameArr[nameArr.length - 1])) {
          baseName = name.substr(0, name.length - 1 - nameArr[nameArr.length - 1].length).trim();
          count = Number(nameArr[nameArr.length - 1]);
        } else {
          baseName = name;
          count = 0;
        }

        return { baseName: baseName, count: count }
      } else {
        return { baseName: name, count: 0 }
      }
    } else {
      return { baseName: "", count: 0 }
    }
  }
  getBaseAbbrivatedName(name: any) {
    if (name) {
      const nameArr: any[] = name.split('-');
      if (nameArr[nameArr.length - 1] === '') {
        nameArr.splice(nameArr.length - 1, 1);
      }
      if (nameArr.length > 1 && !isNaN(nameArr[nameArr.length - 1])) {
        let baseName: any;
        let count = 0;
        if (this.isNumeric(nameArr[nameArr.length - 1])) {
          baseName = name.substr(0, name.length - 1 - nameArr[nameArr.length - 1].length).trim();
          count = Number(nameArr[nameArr.length - 1]);
        } else {
          baseName = name;
          count = 0;
        }
        return { baseName: baseName, count: count }
      } else {
        return { baseName: nameArr.toString(), count: 0 }
      }
    } else {
      return { baseName: "", count: 0 }
    }
  }
  isNumeric(value: any) {
    return /^\d+$/.test(value);
  }
  duplicateFavorite(equipment: any, isFromFavorite?: boolean) {
    const tempEquipment = _.cloneDeep(equipment);
    const orderedFavEquipList = _.orderBy(this.favoriteEquipmentData, ['orderNumber']);
    this.equipmentDetailData = {
      id: Math.random(),
      isDeleted: false,
      equipment: tempEquipment.equipment,
      name: this.changeFavoriteEquipmentName(equipment.name && equipment.name !== '' ? equipment.name : tempEquipment.equipment.name, true),
      abbreviatedName: tempEquipment.abbreviatedName,
      categoryName: tempEquipment.categoryName,
      typeName: tempEquipment.equipment.name,
      serialNumber: tempEquipment.serialNumber,
      partNumber: tempEquipment.partNumber || null,
      dataType: tempEquipment.dataType,
      manufacturer: tempEquipment.manufacturer,
      description: tempEquipment.description,
      installHours: tempEquipment.installHours,
      price: tempEquipment.price,
      budgetedCost: tempEquipment.budgetedCost,
      type: tempEquipment.type,
      deviceLocation: tempEquipment.deviceLocation,
      index: this.favoriteEquipmentData.length,
      isParent: true,
      orderNumber: orderedFavEquipList.length > 0 ? orderedFavEquipList[this.favoriteEquipmentData.length - 1].orderNumber + 1 : 1,
      pinColor: tempEquipment.pinColor,
      isHub: tempEquipment.equipment.isHub
    };
    this.equipmentDetailData.childFavouriteEquipments = [];
    this.equipmentDetailData.serviceTickets = [];

    if (!Utilities.isNull(equipment.childFloorEquipments) && equipment.childFloorEquipments.length > 0) {
      this.equipmentDetailData.name = tempEquipment.name;
    } else {
      const autoName = FloorUtils.checkCountDevice(this.baseNameCount, tempEquipment.name, false);
      if (autoName.length > 75) {
        this.equipmentDetailData.name = autoName.substring(0, autoName.length - (autoName.length - 75));
      } else {
        this.equipmentDetailData.name = tempEquipment.name;
      }
    }

    if (!Utilities.isNull(equipment.childFloorEquipments) && equipment.childFloorEquipments.length > 0) {
      let orderNumber = this.equipmentDetailData.orderNumber;
      equipment.childFloorEquipments.forEach((child: any, key: any) => {
        if (!child.isArchived) {
          orderNumber += 1;
          const updateChild = {
            id: Math.random(),
            isDeleted: false,
            equipment: child.equipment,
            name: child.name ? child.name : child.equipment.name,
            abbreviatedName: child.abbreviatedName,
            categoryName: child.categoryName,
            typeName: child.equipment.name,
            childFloorEquipments: child.childFloorEquipments,
            serialNumber: child.serialNumber,
            partNumber: child.partNumber || null,
            dataType: child.dataType,
            manufacturer: child.manufacturer,
            description: child.description,
            installHours: child.installHours,
            price: child.price,
            projectId: child.projectId,
            type: child.type,
            serviceTickets: [],
            deviceLocation: child.deviceLocation,
            index: this.favoriteEquipmentData.length + (key + 1),
            isParent: false,
            orderNumber: orderNumber,
            pinColor: child.pinColor,
            isHub: child.equipment.isHub,
            budgetedCost: child.budgetedCost,
          };
          this.equipmentDetailData.childFavouriteEquipments.push(updateChild);
        }
      });
    } else {
      this.equipmentDetailData.childFavouriteEquipments = [];
    }
    this.favoriteEquipmentData.push(this.equipmentDetailData);
    this.changeValueFromFavorite.emit({ favoriteEquipmentData: this.favoriteEquipmentData, equipment: this.equipmentDetailData, isBackClickedWithNoDataChange: true, equipmentDetails: this.equipmentDetailData });
    this.selectedFavorite = this.favoriteEquipmentData.length - 1;
    this.openEquipmentDetail(this.equipmentDetailData, this.selectedFavorite);
  }
  showEquipmentModalForFavoritesFromDetail(equipment: any) {
    this.showEquipmentModalForFavorites(equipment, true);
  }
  closeEquipmentDetail(data: any) {
    let canUpdate = false;
    let favDiffData;
    if ((data.equipmentDetails && data.equipmentDetails.childFavouriteEquipments && data.equipmentDetails.childFavouriteEquipments.length > 0) || !this.currentEquipmentDetailsParentIndex) {
      favDiffData = Utilities.difference(data.equipmentDetails, this.favoriteEquipmentData[this.currentEquipmentDetailsIndex])
    } else {
      if (this.favoriteEquipmentData[this.currentEquipmentDetailsParentIndex] && this.favoriteEquipmentData[this.currentEquipmentDetailsParentIndex].childFavouriteEquipments.length > 0) {
        favDiffData = Utilities.difference(data.equipmentDetails, this.favoriteEquipmentData[this.currentEquipmentDetailsParentIndex].childFavouriteEquipments[this.currentEquipmentDetailsIndex])
      }
    }
    // const favDiffData = Utilities.difference(data.equipmentDetails, this.favoriteEquipmentData[this.currentEquipmentDetailsIndex])
    if (favDiffData !== undefined && Object.keys(favDiffData).length > 0) {
      data.equipmentDetails.isFavorateUpdate = true;
      if (data.equipmentDetails.childFavouriteEquipments.length > 0) {
        if (this.currentEquipmentDetailsIndex >= 0) {
          if (this.favoriteEquipmentData[this.currentEquipmentDetailsIndex].name !== data.equipmentDetails.name) {
            canUpdate = true;
          }
        }
        this.favoriteEquipmentData[this.currentEquipmentDetailsIndex] = data.equipmentDetails;
        if (data !== null && !data.showEquipment) {
          this.selectedFavorite = null;
          this.equipmentDetails = null;
        }
      } else {
        if (!Utilities.isNull(this.currentEquipmentDetailsParentIndex) && !Utilities.isNull(this.currentEquipmentDetailsIndex)) {
          if (!data.showEquipment) {
            this.equipmentDetails = data.equipmentDetails;
            this.favoriteEquipmentData[this.currentEquipmentDetailsParentIndex].childFavouriteEquipments[this.currentEquipmentDetailsIndex] = data.equipmentDetails;
            this.openEquipmentDetail(this.favoriteEquipmentData[this.currentEquipmentDetailsParentIndex], this.currentEquipmentDetailsParentIndex);
          } else {
            this.equipmentDetails = data.equipmentDetails;
            this.favoriteEquipmentData[this.currentEquipmentDetailsParentIndex].childFavouriteEquipments[this.currentEquipmentDetailsIndex] = data.equipmentDetails;
          }
        } else {
          if (this.currentEquipmentDetailsIndex >= 0) {
            this.favoriteEquipmentData[this.currentEquipmentDetailsIndex] = data.equipmentDetails;
            if (data !== null && !data.showEquipment) {
              this.selectedFavorite = null;
              this.equipmentDetails = null;
            }
          }
        }
      }

      if (data.isBackClickedWithNoDataChange || data.showEquipment) {
        this.changeValueFromFavorite.emit({ favoriteEquipmentData: this.favoriteEquipmentData, equipment: data.equipmentDetails, showEquipment: data, isBackClickedWithNoDataChange: data.isBackClickedWithNoDataChange });
      }
      if (canUpdate) {
        this.getDeviceNameAndBaseName(false);
      }
    } else {
      if (data !== null && !data.showEquipment) {
        if (this.currentEquipmentDetailsParentIndex && !data.isParentOpenEquipmentDetails) {
          this.openEquipmentDetail(this.favoriteEquipmentData[this.currentEquipmentDetailsParentIndex], this.currentEquipmentDetailsParentIndex);
        } else {
          this.selectedFavorite = null;
          this.equipmentDetails = null;
        }
      }
    }
    this.cdRef.detectChanges();
  }
  openEquipmentDetailFromDetail(details: any) {
    this.currentEquipmentDetailsParentIndex = _.cloneDeep(this.currentEquipmentDetailsIndex);
    this.currentEquipmentDetailsIndex = null;
    if (details.child.id === null) {
      this.currentEquipmentDetailsIndex = details.cindex;
    } else {
      this.currentEquipmentDetailsIndex = details.parent.childFavouriteEquipments.findIndex((e: any) => e.id === details.child.id);
    }
    this.equipmentDetails = details.child;
  }
  childFavouriteEquipmentsCount(item: any) {
    if (this.fromProject) {
      return item.childFavouriteEquipments ? item.childFavouriteEquipments.filter((x: any) => !x.isDeleted).length : 0;
    } else {
      return item.childFavouriteEquipments ? item.childFavouriteEquipments.filter((x: any) => !x.isDeleted && x.type === DeviceTypes.Device).length : 0;

    }
  }
  contextClickFav(event: any, data: any) {
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    this.selectedItemForRemove = data;
    this.contextmenu = true;
    return false;
  }
  contextClick(event: any, data: any) {
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    this.selectedItem = data;
    this.contextmenu = true;
    return false;
  }
  closeContextMenu() {
    this.contextmenu = false;
  }

  favAction(e: any, item: any) {
    this.showEquipmentModalForFavorites(item);
  }
  inputCalled() {
    this.inputFavCalled.emit();
  }
}
