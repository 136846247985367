<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<!-- FLOOR PLAN HEADER START -->
<div *ngIf="isShowLoader && !(loaderService.isLoading | async)">
    <so-loader [mainpageloader]='true'></so-loader>
</div>
<div class="sub-header">
    <div class="d-flex">
        <div class="col ps-0 me-2">
            <so-floor-header *ngIf="buildingDetails" [action]="actionBtn" [totalDiscardCount]="totalDiscardCount"
                [stage]="buildingDetails.stage" [id]="isProject ? buildingDetails.projectId : buildingDetails.siteId"
                [isProject]="isProject" [buildingId]="buildingDetails.id"
                [buildingName]="isProject ? buildingDetails.projectName : buildingDetails.siteName"
                (buildingChangeFromHeader)="buildingChanged($event)" (planChangedFromHeader)="planChanged($event)"
                [floorId]="selectedFloor ? selectedFloor.id : 0" [floorName]="selectedFloor.name"
                [floorList]="buildingDetails.floors" (buildingFloorDropdownData)="buildingFloorDropdownData($event);"
                (redirectPreviousPage)="checkNavigation($event)"></so-floor-header>
        </div>
        <div class="justify-content-end d-flex align-items-center" (click)="$event.stopPropagation();">
            <div class="row">
                <div class="col-xl-12 d-flex align-items-center">
                    <ol class="breadcrumb" style="white-space:nowrap">
                        <li *ngIf="projectId">Project ID: {{projectId}}</li>
                        <li>Plan ID: {{floorId}}</li>
                        <li class="padding-r-0" *ngIf="((isTicketSubmitter && !projectId) || permissions?.canViewOnly)">
                            <span class="lable">View Only</span>
                        </li>
                    </ol>
                    <so-button *ngIf="(!isTicketSubmitter || projectId) && !permissions?.canViewOnly && actionBtn.edit && totalDiscardCount === 0"
                        [className]="'btn btn-primary'" (onClick)="changeBtn('edit')" [showEM]="true"
                        [emClassName]="'fas fa-edit'" [text]="'Edit Plan'">
                    </so-button>
                    <so-button *ngIf="(!isTicketSubmitter || projectId) && !permissions?.canViewOnly && !actionBtn.edit && !actionBtn.save"
                        [disabled]="isImageLoadingLocal" (onClick)="changeBtn('stop')" [className]="'btn btn-secondary'"
                        [text]="'Stop Editing'">
                    </so-button>
                    <span class="d-flex" *ngIf="(!isTicketSubmitter || projectId) &&  !permissions?.canViewOnly && actionBtn.save && totalDiscardCount > 0">
                        <so-button [className]="'btn btn-danger'" [text]="'Discard'" [extraText]="totalDiscardCount"
                            [disabled]="isImageLoadingLocal || (loaderService.isLoading | async) || btnService.getStatus()"
                            (click)="discardChanges()"></so-button>
                        <so-button [className]="'btn btn-primary'" [text]="'Save Changes'"
                            [disabled]="isImageLoadingLocal || btnService.getStatus()  || (loaderService.isLoading | async)"
                            (onClick)="changeBtn('save')"></so-button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- FLOOR PLAN HEADER END-->
<div class="card floor-plan-card">
    <ul class="zoom">
        <li (click)="$event.stopPropagation();zoomChangeWithButtonCall(false,false)">
            <span>
                <em class="far fa-search-minus" title="Zoom Out"></em>
            </span>
        </li>
        <li (click)="$event.stopPropagation();zoomChangeWithButtonCall(true,false)">
            <span>
                <em class="far fa-search-plus" title="Zoom In"></em>
            </span>
        </li>
        <li (click)="$event.stopPropagation();zoomChangeWithButtonCall(undefined,true)">
            <span>
                <em class="far fa-expand-arrows-alt" title="Fit to Screen"></em>
            </span>
        </li>
        <li *ngIf="(actionBtn.save || isLoadingDailyReport)" (click)="$event.stopPropagation();$event.preventDefault()"
            [ngStyle]="{'cursor': 'not-allowed','color' : '#BBC5C9'}">
            <span>
                <em
                    [ngClass]="{'fa-regular fa-file-arrow-down':!isLoadingDailyReport,'fa fa-animated fa-spinner':isLoadingDailyReport}"></em>
            </span>
        </li>
        <li *ngIf="!actionBtn.save && !isLoadingDailyReport" (click)="$event.stopPropagation();downloadReports()"
            [ngStyle]="{'cursor': 'pointer'}">
            <span>
                <em [ngClass]="{'fa-regular fa-file-arrow-down':!isLoadingDailyReport,'fa fa-animated fa-spinner':isLoadingDailyReport}"
                    title="Plan Reports"></em>
            </span>
        </li>

        <li *ngIf="permissions.canEdit && actionBtn.edit" (click)="$event.stopPropagation();"
            [ngStyle]="{'cursor': 'not-allowed','color' : '#BBC5C9'}" title="Add Markup">
            <span>
                <em class="far fa-marker"></em>
            </span>
        </li>
        <li *ngIf="permissions.canEdit  && !actionBtn.edit" (click)="$event.stopPropagation();addMarkup()"
            title="Add Markup">
            <span>
                <em class="far fa-marker"></em>
            </span>
        </li>
    </ul>

    <div class="d-flex" id="floorDragContainer">
        <!-- Equipment List component start-->

        <!-- Equipment List component end-->

        <!-- Equipment Details component start-->

        <!-- Equipment Details component start-->
        <div class="content-wrapper floor-content-wrapper">

            <div class="card floor-card">
                <div class="card-body" id="parentContainer">
                    <div *ngIf="initCall || (loaderService.isLoading | async)" style="z-index: 9;">
                        <so-loader></so-loader>
                    </div>
                    <ul class="count-filter-btn" [ngStyle]="{'bottom':(selectedFloor !== undefined && selectedFloor.floorScale !== undefined && selectedFloor.floorScale !== null && selectedFloor.floorScale > 0) ? '70px':'15px'}">
                        <li class="btn btn-basic" (click)="$event.stopPropagation();showCountFilterPopupOpen('count')"
                            title="Count">
                            <em class="fas fa-info"></em>
                        </li>
                        <li class="btn btn-basic" (click)="$event.stopPropagation();showCountFilterPopupOpen('filter')"
                            title="Filters">
                            <em class="fas fa-filter">{{parentContainer?.height}}</em>
                        </li>

                    </ul>
                    <div class="ruler-card card m-0 align-items-center"
                        [ngClass]="{'count-filter-btn':selectedFloor === undefined || selectedFloor.floorScale === undefined || selectedFloor.floorScale === null ? 'hidden':'inherit'}"
                        *ngIf="selectedFloor !== undefined && selectedFloor.floorScale !== undefined && selectedFloor.floorScale !== null && selectedFloor.floorScale > 0">
                        <div class="d-flex"><strong class="me-1">{{feetValue}} </strong>
                            {{unitOfMeasurements==='Meters'?'meters':'feet'}}
                            <img id="feet-ruler" src="../../../../../../assets/images/icon-ruler.svg" alt="Ruler"
                                width="88px" height="12px" class="ms-2">
                        </div>
                    </div>
                    <so-equipment-list *ngIf="!actionBtn.edit" (deviceUpdate)="updateDeviceLeftTop($event)"
                        [pinSize]="pinSize" [equipmentList]="equipmentList" [isProject]="isProject"
                        [pinColorStatus]="pinColorType" [permissions]="permissions" [isEdit]="!actionBtn.edit"
                        [zoomFactor]="zoomFactor" [favoriteEquipmentData]="favoriteEquipmentData"
                        (changeFav)="getEquipmentListData()" [fromProject]="projectId"
                        (favOrderChanged)="favOrderChanged($event)"
                        [defaultFavoriteEquipmentData]="defaultFavoriteEquipments"
                        (changeValueFromFavorite)="changeValueFromFavorite($event)"
                        (inputFavCalled)="inputCalled()"></so-equipment-list>
                    <so-equipment-details *ngIf="equipmentDetails && deviceStatusData!==undefined"
                        [deviceStatusData]="deviceStatusData" [isCableEnable]="isCableEnable" [defaultFloor]="defaultFloorDetails"
                        (saveImageEquipmentDetails)="saveImageEquipmentDetails($event)" [pinColorType]="pinColorType"
                        (saveEquipmentDetails)="saveEquipmentDetails($event)" (closeEquipment)="closeEquipmentDetails()"
                        [buildingDetails]="buildingDetails" [equipmentDetails]="equipmentDetails"
                        [floorStage]="floorStage" [netWorkSwitches]="netWorkSwitches"
                        [floorScale]="selectedFloor.floorScale" [isTicketSubmitter]="isTicketSubmitter"
                        [installedByUsers]="installedByUsers" [permissions]="permissions" [isEdit]="!actionBtn.edit"
                        [fromProject]="isProject" [isFavorite]="false" [selectedFloor]="selectedFloor"
                        (equipLinkTicket)="equipLinkTicket($event)" (equipCreateTicket)="equipCreateTicket($event)"
                        (selectDeviceFromAssociatedTab)="selectDeviceFromAssociatedTab($event)"
                        [domotzUserId]="buildingDetails.domotzUserId" (inputCalled)="inputCalled()"
                        (removeFlagFromDevice)="removeFlag(true)"
                        (updateCoverage)="updateCoverage($event, true)"></so-equipment-details>
                    <ng-container *ngIf="isConfigLoaded">
                        <so-equipment-filter-count [isDeviceArchived]="showArchivedDevice"
                            (componentClicked)="showCountFilterPopupOpen(selectedFilterCountTab)"
                            [isCableEnable]="isCableEnable" [hidden]="!showCountFilterPopup" [pinSize]="pinSize"
                            [showConnectionLine]="showConnectionLine" [id]="'countFilterComponent'"
                            [stage]=" buildingDetails.stage" [projectId]="projectId" [buildingId]="buildingId"
                            [siteId]="buildingDetails.siteId" [categoryData]="equipmentList"
                            [serviceTickets]="serviceTickets" [selectedFloor]="selectedFloor"
                            [filterCategory]="filterCategoryData" [deviceStatusData]="deviceStatusData"
                            [showCoverage]="showCoverage" [showPartNumber]="showPartNumber"
                            [showAbbreviatedName]="showAbbreviatedName" [showDeviceName]="showDeviceName"
                            [installedByUsers]="installedByUsersItems" [showPlanMarkup]="showPlanMarkup"
                            [hideLiveDevice]="hideLiveDevice" [isLiveDeviceAvailable]="isLiveDeviceAvailable"
                            [showPlanMarkupFilter]="showPlanMarkupFilter" [pinColorType]="pinColorType"
                            [selectedFilterCountTab]="selectedFilterCountTab"
                            (filterCategoryChange)="filterCategoryChange($event)"
                            (pinColorValueUpdate)="pinColorChanged($event)"
                            (pinSizeValueChanged)="pinSizeValueChanged($event)"
                            (liveDeviceToggle)="liveDeviceToggle($event)"
                            (assignedToUserChanged)="assignedToUserChanged($event)">
                        </so-equipment-filter-count>
                    </ng-container>
                    <div id="floorImageContainer" soDraggableImage [zoomFactor]="zoomFactor" [imageZoom]="imageZoom"
                        (imageLoaded)="calculateFloorPlanZoom(true)" (imageLoadedForFit)="calculateFloorPlanZoom()" (wheelEvent)="zoomChanged($event)"
                        [isFitToScreen]="fitToScreen" [syncCompleted]="syncCompleted" (imageDragged)="imageDragged()" class="upload-main-img"
                        [ngStyle]=" {width: zoomValue}">
                        <div id="floorDeviceContainer" (scroll)="$event.stopPropagation()">
                            <img alt="Zoom" [src]="planImage" id="floorImageShadow" class="img-zoom">
                            <div id="FloorContainer" class="drag-blocks"
                                *ngIf="!(loaderService.isLoading | async) && selectedFloor && !initCall">

                                <!-- Primary Device Section Start -->
                                <ng-container
                                    *ngFor="let item of selectedFloor.floorEquipments | floorCategoryFilter :  {'devices': filterCategoryData, 'isProject' : isProject, 'hideLiveDevice': hideLiveDevice, 'assignedToFilter': assignedToFilter, 'isChild': false};let i=index;trackBy: equipTrackBy">
                                    <!-- Cable Path Section Start-->
                                    <so-cable-path
                                        *ngIf="!item.isArchived && (!isProject || (isProject && item.projectId !== null) || (isProject && item.projectId === null && !hideLiveDevice && isLiveSystemAccessForCustomer))"
                                        [filterCategoryData]="filterCategoryData" [isProject]="isProject"
                                        [assignedToFilter]="assignedToFilter" [device]="item" [isEdit]="!actionBtn.edit"
                                        [showConnectionLine]="showConnectionLine"
                                        [equipmentDetailsParent]="equipmentDetailsParent"
                                        [unitOfMeasurements]="unitOfMeasurements" [selectedDevice]="equipmentDetails"
                                        [isCableEnable]="isCableEnable"
                                        (updateConnection)="updateConnectionFlag($event);"
                                        ></so-cable-path>
                                    <!-- Cable Path Section End-->
                                    <div *ngIf="!isProject || (isProject && item.projectId !== null) || (isProject && item.projectId === null && !hideLiveDevice && isLiveSystemAccessForCustomer)"
                                        (click)="$event.stopPropagation();openEquipmentDetail($event,item)"
                                        (mousedown)="$event.stopPropagation()" soDraggableDevice [isProject]="isProject"
                                        (deviceUpdate)="updateDeviceLeftTop($event)" [pinSize]="pinSize" [device]="item"
                                        [isEdit]="!actionBtn.edit && (permissions.canEdit || permissions.canManage) && (!isProject || (isProject && item.projectId !== null)) && !item.isArchived"
                                        id="device-{{item.id}}" [zoomFactor]="zoomFactor" class="drag-container"
                                        [ngStyle]="{ 'left' : item.imageLeft, 'top' : item.imageTop}"
                                        class="secondary-device-type-{{pinSize}}">
                                        <div (contextmenu)="contextClick(item,$event,'primary')">
                                            <div class="drag-scale scale-{{pinSize}}"
                                                [ngClass]="(equipmentDetails && (equipmentDetails.id===item.id || equipmentDetails.parentId===item.id)) ?'device-active' : ''">
                                                <div id="equipment_{{item.id}}" *ngIf="(!item.isDeleted)"
                                                    class="drag-icon" [ngStyle]="{'background':item?.displayPinColor}"
                                                    [ngClass]="item.customClass">
                                                   <div *ngIf="(item.type === 'task')" id="task_arrow_{{item.id}}"
                                                        class="task-arrow"
                                                        [ngStyle]="{'border-top-color':item?.displayPinColor}"></div>
                                                    <i [id]=item.id [ngClass]="item.equipment.iconName"></i>
                                                    <span class="badge" *ngIf="item?.secondaryDeviceCount > 0"
                                                        [ngClass]="item.badgeColor">{{item?.secondaryDeviceCount}}
                                                    </span>
                                                     <span class="badge ticket-count"
                                                        *ngIf="isTicketAccessible && item.serviceTickets && item.serviceTickets.length > 0 && item.showTicketIcon"
                                                        [ngClass]="item.ticketCustomClass"><em class="fas fa-tag"></em>
                                                    </span>
                                                    <span class="badge image-count" *ngIf="item.isImage"
                                                        [ngClass]="item.customClass">
                                                        <em class="fa fa-picture-o"></em>
                                                    </span>
                                                  <span
                                                        *ngIf="isProject && item.flagBadge"
                                                        class="badge flag-badge " [ngClass]="{'task-badge':item.type ==='task'}">
                                                        <em class="fas fa-flag "></em>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="rotate-360" (click)="$event.stopPropagation();"
                                                [ngClass]="{'coverage-device-active': (equipmentDetails && equipmentDetails.id === item.id)}"
                                                id="main-svg-{{item.id}}-{{item.name}}"
                                                *ngIf="item.coverageArea && item.dimension && (showCoverage || (!showCoverage && equipmentDetails && (equipmentDetails.id === item.id || equipmentDetails.parentId === item.id)))">
                                                <svg id="coveragesvg1_{{item.id}}_{{item.name}}"
                                                    [ngClass]="{'coverage-active': (equipmentDetails && equipmentDetails.id === item.id)}"
                                                    class="common-scale-svg coverage-area"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    [ngStyle]="{'width':item.dimensionWidth,'transform':'scale(' + zoomFactor + ')', 'opacity':item.deviceOpacity}"
                                                    viewBox="0 0 200 200">
                                                    <path cx="125" cy="125" class="coverage-scale"
                                                        id="arc1_{{item.id}}_{{item.name}}" [attr.d]="item.dimension"
                                                        stroke-width="200" fill="none"
                                                        [ngStyle]="{'stroke':item.displayCoverageConeColor}"
                                                        data-element-type="resize" />
                                                </svg>
                                                <span
                                                    *ngIf="!actionBtn.edit && (equipmentDetails && equipmentDetails.id === item.id) && (permissions.canEdit || permissions.canManage || permissions.canUpdateDevice) && !item.isLiveDevice && !item.isArchived"
                                                    id="arc_div_{{item.id}}_{{item.name}}" soCoverageRange
                                                    [device]="item" [zoomFactor]="zoomFactor" style="opacity: 0;"
                                                    (updateCoverage)="updateCoverage($event)">
                                                    <svg id="coveragesvg_dev_{{item.id}}_{{item.name}}"
                                                        class="common-scale-svg coverage-svg-area"
                                                        preserveAspectRatio="xMidYMid meet"
                                                        [ngStyle]="{'width':item.dimensionWidth,'height':'200px','pointer-events':'none'}">
                                                        <g id="arc_g1_div_{{item.id}}_{{item.name}}"
                                                            [ngStyle]="{ 'transform-origin': 'center center', 'transform-box': 'fill-box','width': '30px','height': '30px'}">
                                                            <g id="arc_g_div_{{item.id}}_{{item.name}}"
                                                                style="pointer-events:visible">
                                                                <circle id="arc_div_circle_{{item.id}}_{{item.name}}"
                                                                    r="18" opacity="0.5"
                                                                    [attr.fill]="item.displayCoverageConeColor">
                                                                </circle>
                                                                <text id="arc_text_div_{{item.id}}_{{item.name}}"
                                                                    [ngStyle]="{'font-family': fontFamily,'transform':'translate(-7px, 7px)', color: '#262626', 'font-size': '18px'}">
                                                                    <tspan>
                                                                        &#xf424;
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span
                                                    *ngIf="!actionBtn.edit && (equipmentDetails && equipmentDetails.id === item.id) && (permissions.canEdit || permissions.canManage || permissions.canUpdateDevice) && !item.isLiveDevice && !item.isArchived"
                                                    id="arc_div_R_{{item.id}}_{{item.name}}" soCoverageRotate
                                                    style="opacity: 0;" [device]="item"
                                                    (updateCoverage)="updateCoverage($event)">
                                                    <svg id="coveragesvg_dev_R_{{item.id}}_{{item.name}}"
                                                        class="common-scale-svg coverage-svg-area"
                                                        preserveAspectRatio="xMidYMid meet"
                                                        [ngStyle]="{'width':item.dimensionWidth,'height':'200px','pointer-events':'none'}">
                                                        <g id="arc_g2_div_{{item.id}}_{{item.name}}"
                                                            [ngStyle]="{'transform-origin': 'center center', 'transform-box': 'fill-box','width': '30px','height': '30px'}">
                                                            <g id="arc_g_div_R_{{item.id}}_{{item.name}}"
                                                                style="pointer-events:visible">
                                                                <circle id="arc_div_circle_R_{{item.id}}_{{item.name}}"
                                                                    r="18" opacity="0.5"
                                                                    [attr.fill]="item.displayCoverageConeColor">
                                                                </circle>
                                                                <text id="arc_text_div_R_{{item.id}}_{{item.name}}"
                                                                    [ngStyle]="{'transform':'translate(-10px, 7px)','font-family': fontFamily, 'color': '#262626','font-size': '18px'}">
                                                                    <tspan>&#xf01e;</tspan>
                                                                </text>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span
                                                    *ngIf="!actionBtn.edit && (equipmentDetails && equipmentDetails.id === item.id) && (permissions.canEdit || permissions.canManage || permissions.canUpdateDevice) && !item.isLiveDevice && !item.isArchived"
                                                    id="arc_div_LeftSide_{{item.id}}_{{item.name}}" soCoverageLeft
                                                    style="opacity: 0;" [device]="item"
                                                    (updateCoverage)="updateCoverage($event)">
                                                    <svg id="coveragesvg_dev_R_LeftSide_{{item.id}}_{{item.name}}"
                                                        class="common-scale-svg coverage-svg-area"
                                                        preserveAspectRatio="xMidYMid meet"
                                                        [ngStyle]="{'width':item.dimensionWidth,'height':'200px','pointer-events':'none'}">
                                                        <g id="arc_g2_div_LeftSide_{{item.id}}_{{item.name}}"
                                                            style="transform-origin: center center; transform-box: fill-box;width: 30px;height: 30px;">
                                                            <g id="arc_g_div_LeftSide_{{item.id}}_{{item.name}}"
                                                                style="pointer-events:visible">
                                                                <circle
                                                                    id="arc_div_circle_LeftSide_{{item.id}}_{{item.name}}"
                                                                    r="18" opacity="0.5"
                                                                    [attr.fill]="item.displayCoverageConeColor">
                                                                </circle>
                                                                <text
                                                                    id="arc_text_div_LeftSide_{{item.id}}_{{item.name}}"
                                                                    [ngStyle]="{'transform':'translate(-10px, 7px)','font-family': fontFamily, 'color': '#262626', 'font-size': '18px'}">
                                                                    <tspan>&#xf344;</tspan>
                                                                </text>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>


                                                <span
                                                    *ngIf="!actionBtn.edit && (equipmentDetails && equipmentDetails.id === item.id) && (permissions.canEdit || permissions.canManage || permissions.canUpdateDevice) && !item.isLiveDevice && !item.isArchived"
                                                    id="arc_div_RightSide_{{item.id}}_{{item.name}}" soCoverageRight
                                                    style="opacity: 0;" [device]="item"
                                                    (updateCoverage)="updateCoverage($event)">
                                                    <svg id="coveragesvg_dev_R_RightSide_{{item.id}}_{{item.name}}"
                                                        class="common-scale-svg coverage-svg-area"
                                                        preserveAspectRatio="xMidYMid meet"
                                                        [ngStyle]="{'width':item.dimensionWidth,'height':'200px','pointer-events':'none'}">
                                                        <g id="arc_g2_div_RightSide_{{item.id}}_{{item.name}}"
                                                            [ngStyle]="{'transform-origin': 'center center', 'transform-box': 'fill-box','width': '30px','height': '30px'}">
                                                            <g id="arc_g_div_RightSide_{{item.id}}_{{item.name}}"
                                                                style="pointer-events:visible">
                                                                <circle
                                                                    id="arc_div_circle_RightSide_{{item.id}}_{{item.name}}"
                                                                    r="18" opacity="0.5"
                                                                    [attr.fill]="item.displayCoverageConeColor">
                                                                </circle>
                                                                <text
                                                                    id="arc_text_div_RightSide_{{item.id}}_{{item.name}}"
                                                                    [ngStyle]="{'transform':'translate(-7px, 7px)','font-family': fontFamily, 'color': '#262626', 'font-size': '18px'}">
                                                                    <tspan>&#xf343;</tspan>
                                                                </text>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>
                                            </div>
                                            <ng-container *ngIf="item.childFloorEquipments.length > 0">
                                                <ng-container
                                                    *ngFor="let childCov of item.childFloorEquipments | floorCategoryFilter :  {'devices': filterCategoryData, 'isProject' : isProject, 'assignedToFilter': assignedToFilter, 'isChild': true}">
                                                    <div class="rotate-360" (click)="$event.stopPropagation();"
                                                        [ngClass]="{'coverage-device-active': (equipmentDetails && (equipmentDetails.id === childCov.id || equipmentDetails.id === item.id))}"
                                                        id="main-svg-{{childCov.id}}-{{childCov.name}}"
                                                        *ngIf="childCov.coverageArea && childCov.dimension && (showCoverage || (!showCoverage && equipmentDetails && (equipmentDetails.id === childCov.id || equipmentDetails.id === childCov.parentId || item.id === equipmentDetails.parentId)))">
                                                        <svg id="coveragesvg1_{{childCov.id}}_{{childCov.name}}"
                                                            [ngClass]="{'coverage-active': (equipmentDetails && equipmentDetails.id === childCov.id)}"
                                                            class="common-scale-svg coverage-area"
                                                            preserveAspectRatio="xMidYMid meet"
                                                            [ngStyle]="{'width':childCov.dimensionWidth,'transform':'scale(' + zoomFactor + ')', 'opacity':'0.5'}"
                                                            viewBox="0 0 200 200">
                                                            <path cx="125" cy="125" class="coverage-scale"
                                                                id="arc1_{{childCov.id}}_{{childCov.name}}"
                                                                [attr.d]="childCov.dimension" stroke-width="200"
                                                                fill="none"
                                                                [ngStyle]="{'stroke':childCov.displayCoverageConeColor}"
                                                                data-element-type="resize" />
                                                        </svg>
                                                        <span
                                                            *ngIf="!actionBtn.edit && (equipmentDetails && equipmentDetails.id === childCov.id) && (permissions.canEdit || permissions.canManage || permissions.canUpdateDevice) && !childCov.isLiveDevice && !childCov.isArchived"
                                                            id="arc_div_{{childCov.id}}_{{childCov.name}}"
                                                            soCoverageRange [isEdit]="!actionBtn.edit"
                                                            [device]="childCov" [zoomFactor]="zoomFactor"
                                                            style="opacity: 0;"
                                                            (updateCoverage)="updateCoverage($event)">
                                                            <svg id="coveragesvg_dev_{{childCov.id}}_{{childCov.name}}"
                                                                class="common-scale-svg coverage-svg-area"
                                                                preserveAspectRatio="xMidYMid meet"
                                                                [ngStyle]="{'width':childCov.dimensionWidth,'height':'200px','pointer-events':'none'}">
                                                                <g id="arc_g1_div_{{childCov.id}}_{{childCov.name}}"
                                                                    [ngStyle]="{ 'transform-origin': 'center center', 'transform-box': 'fill-box','width': '30px','height': '30px'}">
                                                                    <g id="arc_g_div_{{childCov.id}}_{{childCov.name}}"
                                                                        style="pointer-events:visible">
                                                                        <circle
                                                                            id="arc_div_circle_{{childCov.id}}_{{childCov.name}}"
                                                                            r="18" opacity="0.5"
                                                                            [attr.fill]="childCov.displayCoverageConeColor">
                                                                        </circle>
                                                                        <text
                                                                            id="arc_text_div_{{childCov.id}}_{{childCov.name}}"
                                                                            [ngStyle]="{'font-family': fontFamily,'transform':'translate(-7px, 7px)', color: '#262626', 'font-size': '18px'}">
                                                                            <tspan>
                                                                                &#xf424;
                                                                            </tspan>
                                                                        </text>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span
                                                            *ngIf="!actionBtn.edit && (equipmentDetails && equipmentDetails.id === childCov.id) && (permissions.canEdit || permissions.canManage || permissions.canUpdateDevice) && !childCov.isLiveDevice && !childCov.isArchived"
                                                            id="arc_div_R_{{childCov.id}}_{{childCov.name}}"
                                                            soCoverageRotate [isEdit]="!actionBtn.edit"
                                                            [device]="childCov" style="opacity: 0;"
                                                            (updateCoverage)="updateCoverage($event)">
                                                            <svg id="coveragesvg_dev_R_{{childCov.id}}_{{childCov.name}}"
                                                                class="common-scale-svg coverage-svg-area"
                                                                preserveAspectRatio="xMidYMid meet"
                                                                [ngStyle]="{'width':childCov.dimensionWidth,'height':'200px','pointer-events':'none'}">
                                                                <g id="arc_g2_div_{{childCov.id}}_{{childCov.name}}"
                                                                    [ngStyle]="{'transform-origin': 'center center', 'transform-box': 'fill-box','width': '30px','height': '30px'}">
                                                                    <g id="arc_g_div_R_{{childCov.id}}_{{childCov.name}}"
                                                                        style="pointer-events:visible">
                                                                        <circle
                                                                            id="arc_div_circle_R_{{childCov.id}}_{{childCov.name}}"
                                                                            r="18" opacity="0.5"
                                                                            [attr.fill]="childCov.displayCoverageConeColor">
                                                                        </circle>
                                                                        <text
                                                                            id="arc_text_div_R_{{childCov.id}}_{{childCov.name}}"
                                                                            [ngStyle]="{'transform':'translate(-10px, 7px)','font-family': fontFamily, 'color': '#262626','font-size': '18px'}">
                                                                            <tspan>&#xf01e;</tspan>
                                                                        </text>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span
                                                            *ngIf="!actionBtn.edit && (equipmentDetails && equipmentDetails.id === childCov.id) && (permissions.canEdit || permissions.canManage || permissions.canUpdateDevice) && !childCov.isLiveDevice && !childCov.isArchived"
                                                            id="arc_div_LeftSide_{{childCov.id}}_{{childCov.name}}"
                                                            soCoverageLeft [isEdit]="!actionBtn.edit"
                                                            [device]="childCov" style="opacity: 0;"
                                                            (updateCoverage)="updateCoverage($event)">
                                                            <svg id="coveragesvg_dev_R_LeftSide_{{childCov.id}}_{{childCov.name}}"
                                                                class="common-scale-svg coverage-svg-area"
                                                                preserveAspectRatio="xMidYMid meet"
                                                                [ngStyle]="{'width':childCov.dimensionWidth,'height':'200px','pointer-events':'none'}">
                                                                <g id="arc_g2_div_LeftSide_{{childCov.id}}_{{childCov.name}}"
                                                                    style="transform-origin: center center; transform-box: fill-box;width: 30px;height: 30px;">
                                                                    <g id="arc_g_div_LeftSide_{{childCov.id}}_{{childCov.name}}"
                                                                        style="pointer-events:visible">
                                                                        <circle
                                                                            id="arc_div_circle_LeftSide_{{childCov.id}}_{{childCov.name}}"
                                                                            r="18" opacity="0.5"
                                                                            [attr.fill]="childCov.displayCoverageConeColor">
                                                                        </circle>
                                                                        <text
                                                                            id="arc_text_div_LeftSide_{{childCov.id}}_{{childCov.name}}"
                                                                            [ngStyle]="{'transform':'translate(-10px, 7px)','font-family': fontFamily, 'color': '#262626', 'font-size': '18px'}">
                                                                            <tspan>&#xf344;</tspan>
                                                                        </text>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span
                                                            *ngIf="!actionBtn.edit && (equipmentDetails && equipmentDetails.id === childCov.id) && (permissions.canEdit || permissions.canManage || permissions.canUpdateDevice) && !childCov.isLiveDevice && !childCov.isArchived"
                                                            id="arc_div_RightSide_{{childCov.id}}_{{childCov.name}}"
                                                            soCoverageRight [isEdit]="!actionBtn.edit"
                                                            [device]="childCov" style="opacity: 0;"
                                                            (updateCoverage)="updateCoverage($event)">
                                                            <svg id="coveragesvg_dev_R_RightSide_{{childCov.id}}_{{childCov.name}}"
                                                                class="common-scale-svg coverage-svg-area"
                                                                preserveAspectRatio="xMidYMid meet"
                                                                [ngStyle]="{'width':childCov.dimensionWidth,'height':'200px','pointer-events':'none'}">
                                                                <g id="arc_g2_div_RightSide_{{childCov.id}}_{{childCov.name}}"
                                                                    [ngStyle]="{'transform-origin': 'center center', 'transform-box': 'fill-box','width': '30px','height': '30px'}">
                                                                    <g id="arc_g_div_RightSide_{{childCov.id}}_{{childCov.name}}"
                                                                        style="pointer-events:visible">
                                                                        <circle
                                                                            id="arc_div_circle_RightSide_{{childCov.id}}_{{childCov.name}}"
                                                                            r="18" opacity="0.5"
                                                                            [attr.fill]="childCov.displayCoverageConeColor">
                                                                        </circle>
                                                                        <text
                                                                            id="arc_text_div_RightSide_{{childCov.id}}_{{childCov.name}}"
                                                                            [ngStyle]="{'transform':'translate(-7px, 7px)','font-family': fontFamily, 'color': '#262626', 'font-size': '18px'}">
                                                                            <tspan>&#xf343;</tspan>
                                                                        </text>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                            </ng-container>

                                            <so-context-menu *ngIf="showFirstDragPopup && item.id === listDeviceData.id"
                                                #droppedContext (mousedown)="$event.stopPropagation()"
                                                (click)="$event.stopPropagation()" [DeviceData]="listDeviceData"
                                                [showFirstDragPopup]="showFirstDragPopup" [equipment]="item"
                                                (closeDeviceDropContext)="closeDeviceDroppedContext()"
                                                (saveEquipmentDetail)="saveEquipmentDetailFromContext($event)"
                                                [contextmenuX]="listDeviceData.positionDroppingClient.x"
                                                [contextmenuY]="listDeviceData.positionDroppingClient.y">
                                            </so-context-menu>
                                            <!-- Tool Tip of device Section Start -->
                                            <div class="equipment-tooltip" (mousedown)="$event.stopPropagation();" (mouseup)="$event.stopPropagation();"
                                                [ngClass]="(equipmentDetails && (equipmentDetails.id===item.id || equipmentDetails.parentId===item.id)) ?'device-active' : ''"
                                                id="equipment-tooltip-{{item.id}}" *ngIf="!item.isDeleted">
                                                <div
                                                    *ngIf="(showDeviceName || (showAbbreviatedName && item.abbreviatedName) || (showPartNumber && item.partNumber))">
                                                    <div class="equipment-inner mt-2" [ngClass]="item.tooltipClass"
                                                        [ngStyle]="{'background':(equipmentDetails && (equipmentDetails.id===item.id || equipmentDetails.parentId === item.id)) && pinColorType === 'Device Color' && !item.isArchived ? item.pinColor : '','border-color':item.displayBorderColor,'box-shadow': ((equipmentDetails && (equipmentDetails.id===item.id || equipmentDetails.parentId === item.id)) && pinColorType === 'Device Color' && !item.isArchived) ? '0 0 0 ' + item.pinColor : ''}">
                                                        <span class="equip-part-name" *ngIf="showDeviceName"
                                                            title="{{item.name}}">{{item.name}}</span>
                                                        <span *ngIf="showAbbreviatedName && item.abbreviatedName" [ngClass]="(!showDeviceName) ?'border-none' : ''"
                                                            class="equip-part-number" 
                                                            title="{{item.abbreviatedName}}">{{item.abbreviatedName}}</span>
                                                        <span
                                                            *ngIf="item.type!=='task' && showPartNumber && item.partNumber" [ngClass]="(!showDeviceName && !showAbbreviatedName && item.abbreviatedName) ?'border-none pt-2px' : '' "
                                                            class="equip-part-number"
                                                            title="{{item.partNumber}}">{{item.partNumber}}</span>
                                                    </div>

                                                </div>
                                                <div id="pulse_{{item.id}}" class="pulse" *ngIf="equipmentDetails && (equipmentDetails.id===item.id || equipmentDetails.id===item.parentId) && (((!showDeviceName && !(showPartNumber && (item.partNumber && item.partNumber !== null && item.partNumber !== '')) && !(showAbbreviatedName && (item.abbreviatedName && item.abbreviatedName !== null && item.abbreviatedName !== '')))
                        || (!showAbbreviatedName && !(showDeviceName && (item.name && item.name !== null && item.name !== '')) && !(showPartNumber && (item.partNumber && item.partNumber !== null && item.partNumber !== ''))) 
                        || (!showPartNumber && !(showDeviceName && (item.name && item.name !== null && item.name !== '')) && !(showAbbreviatedName && (item.abbreviatedName && item.abbreviatedName !== null && item.abbreviatedName !== '')))
                        || (!showDeviceName && !showPartNumber && !showAbbreviatedName)))"
                                                    [ngClass]="item.displayPulse">
                                                    <div class="pulse-after"
                                                        [ngStyle]="{'box-shadow':item.displayPulse}"></div>
                                                </div>
                                            </div>
                                            <!-- Tool Tip of device Section End -->
                                        </div>

                                        <!-- Secondary Device Section Starts -->
                                        <ng-container
                                            *ngIf="(item.childFloorEquipments | floorCategoryFilter :  {'devices': filterCategoryData, 'isProject' : isProject, 'assignedToFilter': assignedToFilter, 'isChild': true}) as result">
                                            <div [ngClass]="item.secondaryPopupPosition"
                                                *ngIf="toggleSecondary && (equipmentDetails && (equipmentDetails.id === item.id || equipmentDetails.parentId === item.id)) && result && result.length > 0"
                                                (wheel)="$event.stopPropagation();" (scroll)="$event.stopPropagation();"
                                                id="secondary-device" [ngStyle]="item.secondaryPopUpTop"
                                                class="secondary-devices">
                                                <div class="secondary-inner"
                                                    [ngStyle]="{'padding-top': '10px','padding-bottom': '0px'}">
                                                    <div [ngClass]="item.secondaryPopUpTop === 'secondary-devices-left' ? 'secondary-close-btn left' : 'secondary-close-btn'"
                                                        (click)="closeSecondaryPopup($event)">
                                                        <em class="fas fa-close icon-close"></em>
                                                    </div>
                                                    <div class="device-list"
                                                        (contextmenu)="contextClick(item,$event,'secondary',child)"
                                                        [ngClass]="(equipmentDetails && equipmentDetails.id===child.id) ?'list-active' : ''"
                                                        soDraggableDevice [isProject]="isProject"
                                                        [zoomFactor]="zoomFactor" [pinSize]="pinSize" id="{{child.id}}"
                                                        (deviceUpdate)="updateDeviceLeftTop($event, true)"
                                                        [device]="child"
                                                        [isEdit]="!actionBtn.edit && (permissions.canEdit || permissions.canManage) && (!isProject || (isProject && child.projectId !== null)) && !child.isArchived"
                                                        (click)="$event.stopPropagation();openEquipmentDetail($event,child);"
                                                        *ngFor="let child of item.childFloorEquipments | floorCategoryFilter :  {'devices': filterCategoryData, 'isProject' : isProject, 'hideLiveDevice': hideLiveDevice, 'assignedToFilter': assignedToFilter, 'isChild': true};let k=index;trackBy: childTrackByFn">
                                                        <div id="equipemnt_child_{{child.id}}" class="drag-icon"
                                                            [ngClass]="child.customClass"
                                                            [ngStyle]="{'background':child?.displayPinColor}">
                                                            <div *ngIf="(child.type === 'task')"
                                                                class="task-arrow-child"
                                                                [ngStyle]="{'border-top-color':child?.displayPinColor}">
                                                            </div>
                                                            <i [ngClass]="child.equipment.iconName"></i>
                                                        </div>
                                                        <div class="equipment-tooltip">
                                                            <div class="equipment-inner secondary-active"
                                                                [ngClass]="child.classForSecondary">
                                                                <span class="equip-part-name"
                                                                    title="{{child.name}}">{{child.name}}</span>
                                                                <span
                                                                    *ngIf="child.abbreviatedName && showAbbreviatedName"
                                                                    class="equip-part-number"
                                                                    title="{{child.abbreviatedName}}">{{child.abbreviatedName}}</span>
                                                                <span
                                                                    *ngIf="child.type!=='task' && child.partNumber && showPartNumber"
                                                                    class="equip-part-number"
                                                                    title="{{child.partNumber}}">{{child.partNumber}}</span>
                                                            </div>

                                                            <so-context-menu
                                                                *ngIf="contextOpen && selectedChild && child.id === selectedChild.id"
                                                                [fromDevice]="true"  [selectedItem]="selectedChild"
                                                                [createTicketShow]="isTicketAccessible && !isProject && (permissions.canManage || (isTicketSubmitter && !selectedFloor.isSiteArchived)) && !child.isArchived && !child.isNew && !child.isLiveDevice"
                                                                [linkTicketShow]="isTicketAccessible && !isProject && isLinkToTicket && (permissions.canManage || (isTicketSubmitter && !selectedFloor.isSiteArchived))  && !child.isArchived && !child.isNew && !child.isLiveDevice"
                                                                [createFavoriteShow]="!actionBtn.edit  && !child.isArchived && !child.isLiveDevice && permissions.canManage "
                                                                [deleteFavoriteShow]="false"
                                                                [viewSnapshotShow]="!isProject  && !child.isArchived"
                                                                [duplicateShow]="!actionBtn.edit  && !child.isArchived && !child.isLiveDevice && permissions.canManage"
                                                                [makeHubShow]="!actionBtn.edit  && !child.isArchived && child.type==='device' && !child.isLiveDevice && !child.isHub && permissions.canManage"
                                                                [removeHubShow]="!actionBtn.edit  && !child.isArchived && !child.isLiveDevice && child.type==='device' && child.isHub && permissions.canManage"
                                                                [addFlagShow]="!actionBtn.edit  && !child.isArchived && !child.isLiveDevice && isProject && !child.isFlag"
                                                                [removeFlagShow]="!actionBtn.edit  && !child.isArchived && !child.isLiveDevice && isProject && child.isFlag"
                                                                [archiveShow]="!actionBtn.edit  && !child.isArchived && !child.isNew && !child.isLiveDevice && permissions.canManage"
                                                                [unArchiveShow]="!actionBtn.edit && child.isArchived && !child.isNew && !child.isLiveDevice && permissions.canManage"
                                                                [deleteShow]="!actionBtn.edit && (child.isArchived || child.isNew) && !child.isLiveDevice && permissions.canManage"
                                                                [viewHistoryShow]="child.id >=1"
                                                                [contextmenuX]="contextmenuX"
                                                                [contextmenuY]="contextmenuY" [isEdit]="!actionBtn.edit"
                                                                (contextMenuClicked)="contextAction($event)"></so-context-menu>
                                                        </div>
                                                        <div class="secondary-status">
                                                            <ul>
                                                                <li *ngIf="child.isFlag && isProject">
                                                                    <em class="fas fa-flag red-text"></em>
                                                                </li>
                                                                <li
                                                                    *ngIf="isTicketAccessible && child.serviceTickets && child.serviceTickets.length > 0 && child.showChildTicketIcon">
                                                                    <em class="fas fa-tag"
                                                                        [ngClass]="child.ticketCustomClass"></em>
                                                                </li>
                                                                <li *ngIf="child.isImage"><em
                                                                        class="fas fa-image "></em>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <!-- Secondary Device Section Ends-->
                                    </div>
                                    <so-context-menu
                                        *ngIf="contextOpen && selectedItem && item.id === selectedItem.id && !selectedChild"
                                        [fromDevice]="true" [selectedItem]="selectedItem"
                                        [createTicketShow]="isTicketAccessible && !isProject && (permissions.canManage || (isTicketSubmitter && !selectedFloor.isSiteArchived)) && !item.isNew && !item.isLiveDevice && !item.isArchived"
                                        [linkTicketShow]="isTicketAccessible && !isProject && isLinkToTicket && (permissions.canManage  || (isTicketSubmitter && !selectedFloor.isSiteArchived)) && !item.isNew && !item.isLiveDevice && !item.isArchived"
                                        [createFavoriteShow]="!actionBtn.edit && !item.isLiveDevice && permissions.canManage && !item.isArchived "
                                        [deleteFavoriteShow]="false" [viewSnapshotShow]="!isProject && !item.isArchived"
                                        [duplicateShow]="!actionBtn.edit && !item.isLiveDevice && !item.isArchived && permissions.canManage"
                                        [makeHubShow]="!actionBtn.edit && item.type==='device' && !item.isArchived && !item.isLiveDevice && !item.isHub && permissions.canManage"
                                        [removeHubShow]="!actionBtn.edit && !item.isLiveDevice && !item.isArchived && item.type==='device' && item.isHub && permissions.canManage"
                                        [addFlagShow]="!actionBtn.edit && !item.isLiveDevice && !item.isArchived && isProject && !item.isFlag"
                                        [removeFlagShow]="!actionBtn.edit && !item.isLiveDevice && !item.isArchived && isProject && item.isFlag"
                                        [archiveShow]="!actionBtn.edit && !item.isArchived && !item.isNew && !item.isLiveDevice && permissions.canManage"
                                        [unArchiveShow]="!actionBtn.edit && item.isArchived && !item.isNew && !item.isLiveDevice && permissions.canManage"
                                        [deleteShow]="!actionBtn.edit && (item.isArchived || item.isNew) && !item.isLiveDevice && permissions.canManage"
                                        [viewHistoryShow]="item.id >= 1" [contextmenuX]="contextmenuX"
                                        [contextmenuY]="contextmenuY" [isEdit]="!actionBtn.edit"
                                        (contextMenuClicked)="contextAction($event)"></so-context-menu>
                                </ng-container>
                                <!-- Primary Device Section End -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- edit site end here-->
            <!-- count filter component start-->
        </div>
    </div>
</div>