<div style="z-index: 999" id="equip-label" (click)="goToRefrenceDevice()" *ngIf="fromProject && equipmentDetails?.isPublishedToAMT"
    style="cursor: pointer;" [title]="publishedTooltipMessageText" class="published-title">
    <span style="z-index: 999">PUBLISHED &nbsp;<em *ngIf="isLiveSystemAccessForCustomer"
            class="far fa-angle-right"></em></span>

</div>
<div style="z-index: 999" id="equip-label" (click)="goToRefrenceDevice()"
    *ngIf="fromProject && !equipmentDetails?.isPublishedToAMT && !equipmentDetails?.isArchived && equipmentDetails?.projectId === null"
    style="cursor: pointer;" [title]="publishedTooltipMessageText" class="site-device-title">
    <span style="z-index: 999">LIVE DEVICE</span>
</div>

<div style="z-index: 999" id="equip-label" (click)="goToRefrenceDevice()" *ngIf="equipmentDetails?.isArchived && !equipmentDetails?.isPublishedToAMT" [title]="publishedTooltipMessageText"
    class="archived-title">
    <span *ngIf="buildingDetails?.isSiteArchived && !buildingDetails?.isProjectArchived" style="z-index: 999">Plan
        Archived </span>
    <span *ngIf="buildingDetails?.isProjectArchived && !buildingDetails?.isSiteArchived" style="z-index: 999">Plan
        Archived </span>
    <span *ngIf="!buildingDetails?.isSiteArchived && !buildingDetails?.isProjectArchived" style="z-index: 999">Archived
    </span>
</div>

<div style="z-index: 999" id="equip-label" (click)="goToRefrenceDevice()" *ngIf="!equipmentDetails?.isArchived && buildingDetails?.isSiteArchived"
    [title]="publishedTooltipMessageText" class="archived-title">
    <span style="z-index: 999">Plan Archived </span>
</div>

<div style="z-index: 999" id="equip-label" (click)="goToRefrenceDevice()"
    *ngIf="equipmentDetails?.archivedStatus!=='Active' && !equipmentDetails?.isArchived && !equipmentDetails?.isPublishedToAMT && buildingDetails?.isProjectArchived"
    [title]="publishedTooltipMessageText" class="archived-title">
    <span style="z-index: 999">Plan Archived </span>
</div>